import * as React from "react";
import { InjectedFormProps, reduxForm } from "redux-form";
import Button from "src/components/Button";
import TextInput from "src/components/TextInput";
import Typography from "src/components/Typography";
import styles from "./ForgetPassword.module.css";

interface Props extends InjectedFormProps<FormData> {}

type FormData = {
  email: string;
};

class RegistrationFrom extends React.Component<Props> {
  public render() {
    const { props } = this;
    const isDisabled = !!(
      props.submitting ||
      !props.anyTouched ||
      props.error ||
      props.invalid
    );

    return (
      <form onSubmit={props.handleSubmit} className={styles.formWrapper}>
        <Typography type="h3" black>
          <h3>Забыли пароль?</h3>
        </Typography>
        <Typography type="text" black>
          Введите свой e-mail, чтобы сбросить текущий пароль. На Вашу почту
          придет инструкция для смены пароля.
        </Typography>
        <div className={styles.inputWrapper}>
          <TextInput name="email" label="email" black />
        </div>
        <div className={styles.buttonWrapper}>
          <Button disabled={isDisabled} type="submit">
            СБРОСИТЬ ПАРОЛЬ
          </Button>
        </div>
      </form>
    );
  }
}

const validate = (values: { email: string; password: string }) => {
  const errors: { email?: string } = {};
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address";
  }
  return errors;
};

export default reduxForm<FormData>({
  form: "ForgetPasswordFrom", // a unique identifier for this form
  validate,
})(RegistrationFrom);
