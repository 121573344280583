import React from "react";
import Seo from "src/components/Seo";
import ForgetPasswordRoute from "src/routes/ForgetPassword";
import { PageProps } from "src/types";

const ForgetPassword = (props: PageProps) => (
  <>
    <Seo pageTitle="Забыли пароль" />
    <ForgetPasswordRoute {...props} />
  </>
);

export default ForgetPassword;
