import * as React from "react";
import Api from "src/api";
import icon from "src/images/resetPassIcon.svg";
import FullWithLayout from "src/routes/FullWithLayout";
import Form from "./Form";

import { navigate } from "gatsby";
import styles from "./ForgetPassword.module.css";

class ForgetPassword extends React.Component<any, any> {
  public render() {
    return (
      <FullWithLayout white sideBar={<img src={icon} />}>
        <div className={styles.wrapper}>
          <Form onSubmit={this.handleSubmit} />
        </div>
      </FullWithLayout>
    );
  }

  private handleSubmit = async (data: { email: string }) => {
    await Api.sendForgetPasswordLink(data.email);
    await navigate("/");
  };
}

export default ForgetPassword;
